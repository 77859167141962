import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Container
} from '@chakra-ui/react'

export default function PleinAirSurvey() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid, errors },
      } = useForm({
        mode: "onSubmit",
      })

    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);

    const onSubmit = data => {
        setProcessing(true);
    
        // Gatsby check to make sure document is defined
     let surveyData = null
     if (typeof document !== undefined) {
         surveyData = document.forms["survey-form"]
       }
       //ev.preventDefault();

        //setDisableSubmit(true);
        setProcessing(true);

    //console.log(JSON.stringify(surveyData))
    //setsucceeded2`(true);

    fetch(`https://script.google.com/macros/s/AKfycbzpEobJ4RBiFC3NCPpRMA5zHI2ODlZSwwtFKLo6hz9yQOIWeQDU2vjkLxK-4gINj1K9WA/exec`,
    {
        method: "POST",
        body: new FormData(surveyData),
    }).then((response) => {
        if (response.status === 200) {
          console.log('Data submitted to Google Sheet')
          setProcessing(false);
          setSucceeded(true);
          reset();
        } else {
            console.log('Error submiting to Google Sheet')
        }
    })
    .catch((error) => console.log('Google error!', error.message))      
  setTimeout((data) => { 
  }, 4000)
        
          }
    
      const SuccessMessage = () => (
        <Box>
        <Alert status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius="1rem"
          >
        <AlertIcon boxSize='40px' mr={0}/>
          <AlertTitle mb="4">
            Thank you for your response.
            </AlertTitle>
            <AlertDescription maxWidth='md'>
        We look forward to seeing you at the event!
      </AlertDescription>
        </Alert>
      </Box>
      )

    return (
        <Container maxWidth="container.sm">
             { succeeded 
             ? <SuccessMessage /> 
             : (
                <>
                    <form onSubmit={handleSubmit(onSubmit)} id="survey-form" name="survey-form">
                        <Flex w="100%" flexWrap="wrap">
                            <Box mb={8} pr={{base: 0, lg: '2'}} w="100%">
                                <FormControl>
                                    <FormLabel>
                                        How did you hear about Driggs Plein Air?
                                    </FormLabel>
                                    <Textarea {...register("hear_about")} />
                                </FormControl>
                            </Box>
                            <Box mb={8} pr={{base: 0, lg: '2'}}w="100%">
                                <FormControl>
                                    <FormLabel>
                                        Where do you plan to stay during the event?
                                    </FormLabel>
                                    <Textarea {...register("plan_to_stay")} />
                                </FormControl>
                            </Box>
                            <Box mb={8} pr={{base: 0, lg: '2'}}w="100%">
                                <FormControl>
                                    <FormLabel>
                                        What is your zip code?
                                    </FormLabel>
                                    <Input type="number" {...register("zip_code")} />
                                </FormControl>
                            </Box>
                        </Flex>
                        <Box>
                            <Button
                                className="submit"
                                variant="plein-air" 
                                disabled={processing}
                                type="submit"
                                mt={4}
                                isLoading={processing}
                                loadingText='Submitting'
                                >
                                Submit
                            </Button>
                         </Box>
                    </form>
                </>
             ) }
        </Container>
    )
}